import React from 'react'
import styled, {css} from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { Link } from 'gatsby'
import imac from '../images/imac27-pf-screen.png'

const BlockCont = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  line-height: 2em;
  &:nth-child(even) {
    flex-flow: row-reverse wrap;
  }
`

const BlockItem = styled.div`
  min-width: 350px;
  max-width: 400px;
  margin: 80px auto;
  ${({inView, imacBg}) => !inView
    ? css`
      transform: translateX(${imacBg? "50px": "-50px"});
      opacity: 0;
      `
    : null}
  transition: all 0.5s ease-out ${({imacBg}) => !imacBg? '0.2s': null};
  ${({imacBg}) => imacBg
    ? css`
      background: url("${imac}") no-repeat top/cover;
      padding: 1.5% 1.5% 8%;
      box-sizing: border-box;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      `
    :null}
`

const FullLengthBlockItem = styled.div`
  width: 100%;
  ${({inView}) => !inView
    ? css`
      transform: translateX(-50px);
      opacity: 0;
      `
    : null}
  transition: all 0.5s ease-out 0.4s;
`

const FullLengthFlexBox = styled(FullLengthBlockItem)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: flex-start;
`

const BlockImg = styled.img`
  display: block;
  width: 280px;
  margin: 30px auto;
  ${({inView}) => !inView
    ? css`
      transform: translateX(50px);
      opacity: 0;
      `
    : null}
  transition: all 0.5s ease-out;
`

const StyledLink = styled(Link)`
  display: block;
  text-align: right;
  text-decoration: none;
  color: salmon;
  font-weight: bold;
`

const ContentBlock = ({childKey, heading, text, video, fullLengthText, image, list, link, imacContent, buttons, threshold}) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: threshold || 0.4,
  })
  return (
    <BlockCont ref={ref}>
      {image && <BlockImg src={image} inView={inView}/>}
      {imacContent && <BlockItem inView={inView} imacBg><img src={imacContent} alt="" style={{objectFit: 'contain'}}/></BlockItem>}
      <BlockItem inView={inView}>
        {heading}
        {text && text}
        {list && 
          <ul style={{lineHeight: '1.2em'}}>
            {list.map((item, idx) => 
              <React.Fragment key={`${childKey}-li-${idx}`}>{item}</React.Fragment>
            )}
          </ul>
          }
        {link &&
          <StyledLink to={link} >
            ...Learn More
          </StyledLink>}
      </BlockItem>
      {video && (
        <FullLengthBlockItem style={{textAlign: "center"}} inView={inView}>
          {video}
        </FullLengthBlockItem>
      )}
      {fullLengthText && 
        fullLengthText.map((par, idx) => (
        <FullLengthBlockItem key={`${childKey}-ft-${idx}`} inView={inView}>
          {par}
        </FullLengthBlockItem>
        ))}
      {buttons && 
        <FullLengthFlexBox inView={inView}>
          {buttons.map((btn, idx) => (
            <span key={`${childKey}-btn-${idx}`}>
              {btn}
            </span>
          ))}
        </FullLengthFlexBox>
        }
    </BlockCont>
  )
}

export default ContentBlock